import React  from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonItem, IonText, IonFooter, IonTitle, IonToolbar } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { calendar, location, informationCircle, people, documentOutline, homeOutline, personCircle } from 'ionicons/icons';
import Passport from './Passport';
import Fingerprint311 from './Fingerprint311';
import FingerprintCourt from './FingerprintCourt';
import PassportApt from './PassportApt';
import Fingerprint311Apt from './Fingerprint311Apt';
import FingerprintCourtApt from './FingerprintCourtApt';
import Cancel from './Cancel';
import './MainTabs.css';

interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => {

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/passport" />



        <Route
          path="/tabs/cancel/:keyvalue?"
          render={(props: any) => (
            <Cancel
              {...props}
              title="Cancel Appointment"
            />
          )}
        />



        <Route
          path="/tabs/passport/:dateValue?"
          render={(props: any) => (
            <Passport
              {...props}
              title="Passport"
            />
          )}
        />


        <Route
          path="/tabs/Fingerprint311/:dateValue?/:show?"
          render={(props: any) => (
            <Fingerprint311
              {...props}
              title="311 Fingerprint"
            />
          )}
        />



        <Route
          path="/tabs/passportapt/:keyValue?/:start?/:end?"
          render={(props: any) => (
            <PassportApt
              {...props}
              title="Passport Details"
            />
          )}
        />


        <Route
          path="/tabs/fingerprint311apt/:keyValue?/:show?"
          render={(props: any) => (
            <Fingerprint311Apt
              {...props}
              title="Fingerprint311 Apt Details"
            />
          )}
        />

      
        <Route
          path="/tabs/FingerprintCourt/:keyValue?/:start?/:end?"
          render={(props: any) => (
            <FingerprintCourt
              {...props}
              title="Court Fingerprint"
            />
          )}
        />


        <Route
          path="/tabs/fingerprintcourtapt/:keyValue?/:start?/:end?"
          render={(props: any) => (
            <FingerprintCourtApt
              {...props}
              title="Fingerprint Court Apt Details"
            />
          )}
        />

      </IonRouterOutlet>


      {/*This is the bottom menu*/}
      
      <IonTabBar slot="bottom">
        {/*<IonTabButton tab="" >*/}
        {/*  <div style={{ backgroundColor: '#2dd36f', width: '6px' }}>&nbsp;</div><IonLabel color="success">Available</IonLabel>*/}
        {/*</IonTabButton>*/}
        {/*<IonTabButton tab="">*/}
        {/*  <div style={{ backgroundColor: '#5260ff', width: '6px' }}>&nbsp;</div> <IonLabel color="tertiary">Taken</IonLabel>*/}
        {/*</IonTabButton>*/}
        {/*<IonTabButton tab="">*/}
        {/*  <div style={{ backgroundColor: '#ffc409', width: '6px' }}>&nbsp;</div> <IonLabel color="warning">Weedend/Holiday</IonLabel>*/}
        {/*</IonTabButton>*/}
        {/*<IonTabButton tab="">*/}
        {/*  <div style={{ backgroundColor: '#92949c', width: '6px' }}>&nbsp;</div> <IonLabel color="medium">Past</IonLabel>*/}
        {/*</IonTabButton>*/}
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
