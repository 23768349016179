import React, { useState } from 'react';
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardContent, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonText, IonDatetime, IonIcon, IonItemGroup, IonItemDivider } from '@ionic/react';
import { useQuery } from 'react-query';
import { GetAppointmentsByDate } from '../data/api';
import { Link, RouteComponentProps } from 'react-router-dom';
import { arrowDownCircle, chevronForwardCircle, homeOutline} from 'ionicons/icons';

interface StateProps {
  title: string
}


interface RouteProps extends RouteComponentProps<{  dateValue: string, show : string }> { }
interface GenericProps extends RouteProps, StateProps { }

const Fingerprint311: React.FC<GenericProps> = ({ match, title }) => {

  let today = new Date(new Date().toDateString());
  let showEmployeeTab = "N";

  if (match.params.dateValue === null) {
    if (match.params.dateValue !== null && match.params.dateValue !== undefined && match.params.dateValue !== "Y")
      today = new Date(new Date(match.params.dateValue).toDateString());
  }

  if (match.params.dateValue !== null && match.params.dateValue !== undefined) {
    if (match.params.dateValue === "Y")
      showEmployeeTab = "Y";
  }

  if (match.params.dateValue !== null && match.params.show !== undefined) {
    showEmployeeTab = "Y";
  }


  const [selectYear, setSelectYear] = useState<string>(today.getFullYear().toString());
  const [selectedDate, setSelectedDate] = useState<string>(today.toISOString());


  //---GetDatetList--------------------------------
  function GetDatetList(props: any) {
    const myQuery = useQuery("GetAppointmentsByDateF", () => GetAppointmentsByDate(props.myType, props.myDate));
    return (
      <div>
        {myQuery.isLoading && (
          <IonItem>
            Loading
          </IonItem>
        )}
        {
          myQuery.isSuccess && myQuery.data.map((dateString: any) =>
          (



            <IonItem key={dateString.aptkey}>
              <div style={{
                backgroundColor: dateString.aptsts === 'N' ? '#5260ff' :
                  dateString.aptsts === 'H' ? '#ffc409' :
                    dateString.aptsts === 'P' ? '#92949c'
                      : '#2dd36f', width: "4px"
              }} slot="start" >&nbsp;</div>
              <div>
                {dateString.aptsts === 'A' ? (
                  <div>
                    <Link
                      to={{
                        pathname: "/tabs/Fingerprint311apt/" + dateString.aptkey.toString().replaceAll("/", "|") + "/" + showEmployeeTab
                      }}>
                      {dateString.aptst}
                        -
                      {dateString.apted}
                    </Link>
                  </div>
                ) :
                  (
                    <div style={{ color: "lightgray" }} >
                      {dateString.aptst}
                        -
                      {dateString.apted}
                    </div>

                  )
                }



              </div>

              <div slot="end" style={{ color: "lightgray" }}>
                {dateString.aptname}
              </div>

            </IonItem>))
        }
      </div>
    );
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">311 Fingerprint Services </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <IonCard color="warning">
          <IonCardHeader>
            <IonItemGroup className="instructionbox">
              <IonText>
                <IonIcon icon={homeOutline} size="large"></IonIcon><br></br>
                311 Help Desk (Room 110)<br></br>
                56 North State Street, Orem, UT 84057<br></br>
                Monday thru Thursday: 08:00 AM to 05:00 PM<br></br>
                Saturday & Sunday:  Closed<br></br>
            </IonText>
            </IonItemGroup>
            
            <IonText class="instruction">
              <br></br>
          To assist you best, please make sure you have selected the correct scheduling service.
          Fingerprint 311 is for employment purposes and Fingerprint Court is for those with a court
          case at the Orem Justice Court.  Use the Selected Date button to change the date,
          then utilize the color coding to determine an open slot. Once you find a time that works for you, double click on it and complete the needed information. <br></br><br></br>

          Fingerprinting is by appointment only. Only the person being fingerprinted will be allowed in the fingerprinting room. We do not fingerprint anyone under the age of 16. Please do not bring minor children to your fingerprinting appointment as we will not be able to process your fingerprints and you will need to reschedule. <br></br><br></br>



          You will receive a confirmation email that your appointment has been set. If you have any questions contact
          us at 801-229-7275 . 
          <br></br><br></br>
          </IonText>

          </IonCardHeader>
        </IonCard>

  
        <IonCard>
          <IonCardContent>
            <IonItem lines="none">
              <IonIcon slot="start" icon={arrowDownCircle} color="primary"></IonIcon>
              <IonLabel>
                Selected Date
              </IonLabel>
            </IonItem>
            <IonItem mode="ios">
              <IonDatetime
                value={selectedDate}
                presentation="date"
                onIonChange={(e: any) => { setSelectedDate(e.detail.value!) }}
              />
            </IonItem>
            <br></br>
            <IonList>
              <GetDatetList myType='F'  myDate={selectedDate} />
            </IonList>

          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
}

export default Fingerprint311;
