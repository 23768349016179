import axios from 'axios';

export async function GetAppointmentsByDate(valType, valDate) {
  return axios
    .get("/api/GetAppointmentsByDate?valType="+valType+"&valDate=" + valDate)
    .then(res => res.data);
}


export async function IsSlotAvailable(valDate1, valDate2, valType) {
  return axios
    .post('/api/IsSlotAvailable', { Aptst: valDate1, Apted: valDate2, Apttype: valType })
    .then(res => res.data)
}


export async function IsNextSlotAvailable(valDate1, valDate2, valType) {
  return axios
    .post('/api/IsNextSlotAvailable', { Aptst: valDate1, Apted: valDate2, Apttype: valType })
    .then(res => res.data)
}



export async function SendEmail(valFrom, valTo, valSubject, valContent) {
  return axios
    .post('/api/SendEmail', { ToEmail: valTo, FromEmail: valFrom, Subject: valSubject, Content: valContent, Attachment: '', IsHtml: true })
    .then(res => res.data)
     
}

export async function AddApptRecord(valDate1, valDate2, valType, valEmail, valName, valSlot) {
  return axios
    .post('/api/AddApptRecord', { Aptst: valDate1, Apted: valDate2, Apttype: valType, Aptemail: valEmail, Aptname: valName, Aptslot: valSlot })
    .then(res => res.data)
}

export async function GetBirthPlaces() {
  return axios
    .get("/api/GetBirthPlace")
    .then(res => res.data);
}

export async function GetRaces() {
  return axios
    .get("/api/GetRace")
    .then(res => res.data);
}

export async function GetEyeColors() {
  return axios
    .get("/api/GetEyeColor")
    .then(res => res.data);
}

export async function GetHairColors() {
  return axios
    .get("/api/GetHairColor")
    .then(res => res.data);
}

export async function WriteXMLFile(valLastname, valFirstname, valMiddlename, valEmail, valDateofbirth, valBirthplace, valRace, valGender, valEye, valHair, valHeight, valWeight) {
  return axios
    .post('/api/WriteXMLFile', {
      lastname: valLastname,
      firstname: valFirstname,
      middlename: valMiddlename,
      email: valEmail,
      dateofbirth: valDateofbirth,
      birthplace: valBirthplace,
      race: valRace,
      gender: valGender,
      eye: valEye,
      hair: valHair,
      height: valHeight,
      weight: valWeight
    })
    .then(res => res.data)
}

export async function AppointmentExists(valkey) {
  return axios
    .get("/api/AppointmentExists?keyvalue=" + valkey)
    .then(res => res.data);
}

export function CancelAppointment(valkey) {
  return axios
    .post('/api/CancelAppointment', { uid: valkey })
    .then(res => res.data)
}

export async function GetEmployeeInfo(valkey) {
  return axios
    .get("/api/GetEmployeeInfo?keyvalue=" + valkey)
    .then(res => res.data);
}
