import React, {useState } from 'react';
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardContent, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonText, IonDatetime, IonIcon, IonItemGroup, IonItemDivider } from '@ionic/react';
import { useQuery } from 'react-query';
import { GetAppointmentsByDate } from '../data/api';
import { Link, RouteComponentProps } from 'react-router-dom';
import { arrowDownCircle, chevronForwardCircle, homeOutline } from 'ionicons/icons';

interface StateProps {
  title: string
}


interface RouteProps extends RouteComponentProps<{ dateValue: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const FingerprintCourt: React.FC<GenericProps> = ({ match, title }) => {


  //---Set Intial Value of the Date--------------------------------
  if (match.params.dateValue == null) {
    match.params.dateValue = new Date().toISOString();
  }

  let today = new Date(new Date(match.params.dateValue).toDateString());

  const [selectYear, setSelectYear] = useState<string>(today.getFullYear().toString());
  const [selectedDate, setSelectedDate] = useState<string>(today.toISOString());



  //var iMonth = today.getMonth();

  //let monthValues = "";
  //for (let i = iMonth + 1; i < 13; i++) {
  //  if (i != 12) {
  //    monthValues = monthValues.concat(i.toString() + ',');
  //  }
  //  else {
  //    monthValues = monthValues.concat(i.toString());
  //  }

  //}

  //function daysInMonth(myMonth: any, myYear: any) {
  //  return new Date(myYear, myMonth, 0).getDate();
  //}


  //let daysValues = "";
  //let totalDays = daysInMonth(iMonth, selectYear);
  //for (let i = 0; i < totalDays - 1; i++) {
  //  if (today.getMonth === new Date(selectedDate).getMonth) {


  //  }
  //  else {
  //    if (i != (totalDays - 1)) {
  //      daysValues = daysValues.concat(i.toString() + ',');
  //    }
  //    else {
  //      daysValues = daysValues.concat(i.toString());
  //    }

  //  }

  //}


  //---GetDatetList--------------------------------
  function GetDatetList(props: any) {
    const myQuery = useQuery("GetAppointmentsByDateC", () => GetAppointmentsByDate(props.myType, props.myDate));
    return (
      <div>
        {myQuery.isLoading && (
          <IonItem>
            Loading
          </IonItem>
        )}
        {
          myQuery.isSuccess && myQuery.data.map((dateString: any) =>
          (



            <IonItem key={dateString.aptkey}>
              <div style={{
                backgroundColor: dateString.aptsts === 'N' ? '#5260ff' :
                  dateString.aptsts === 'H' ? '#ffc409' :
                    dateString.aptsts === 'P' ? '#92949c'
                      : '#2dd36f', width: "4px"
              }} slot="start" >&nbsp;</div>
              <div>
                {dateString.aptsts === 'A' ? (
                  <div>
                    <Link
                      to={{
                        pathname: "/tabs/Fingerprintcourtapt/" + dateString.aptkey.toString().replaceAll("/", "|")
                      }}>
                      {dateString.aptst}
                        -
                      {dateString.apted}
                    </Link>
                  </div>
                ) :
                  (
                    <div style={{ color: "lightgray" }} >
                      {dateString.aptst}
                        -
                      {dateString.apted}
                    </div>

                  )
                }



              </div>

              <div slot="end" style={{ color: "lightgray" }}>
                {dateString.aptname}
              </div>

            </IonItem>))
        }
      </div>
    );
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Court Fingerprint Services</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <IonCard color="warning">
          <IonCardHeader>
            <IonItemGroup className="instructionbox">
              <IonText>
                <IonIcon icon={homeOutline} size="large"></IonIcon><br></br>
                97 East Center Street, Orem, UT 84057<br></br>
                 Monday thru Thursday: 11:00 AM to 04:00 PM<br></br>
               Friday: 8:00 AM to 04:00 PM<br></br>
                Saturday & Sunday:  Closed<br></br>
              </IonText>
            </IonItemGroup>
            <IonText class="instruction">
              <br></br>
          To assist you best, please make sure you have selected the correct scheduling service.
          Fingerprint 311 is for employment purposes and Fingerprint Court is for those with a court
          case at the Orem Justice Court.  Use the Selected Date button to change the date,
          then utilize the color coding to determine an open slot.
          Once you find a time that works for you, double click on it and complete the needed information.
          You will receive a confirmation email that your appointment has been set. If you have any questions contact
          us at 801-724-3900 (jcorem@utcourts.gov) .
          <br></br>
          </IonText>

          </IonCardHeader>
        </IonCard>

        
        <IonCard>
          <IonCardContent>
            
            <IonItem lines="none">
              <IonIcon slot="start" icon={arrowDownCircle} color="primary"></IonIcon>
              <IonLabel>
                Selected Date
              </IonLabel>
            </IonItem>
            <IonItem mode="ios">
              <IonDatetime
                value={selectedDate}
                presentation="date"
                onIonChange={(e: any) => { setSelectedDate(e.detail.value!) }}
              />
            </IonItem>
            <br></br><br></br>


            <IonList>
              <GetDatetList myType='C' myDate={selectedDate} />
            </IonList>

          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
}

export default FingerprintCourt;
