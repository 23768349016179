import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonButtons, IonInput, IonButton, IonSelect, IonSelectOption, IonIcon, useIonAlert, IonTextarea } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Formik } from 'formik';
import { Fingerprint311Schema } from '../data/validations';
import { star, returnDownBack, mailOutline } from 'ionicons/icons';
import { GetBirthPlaces, GetRaces, GetEyeColors, GetHairColors, WriteXMLFile, IsSlotAvailable, AddApptRecord, SendEmail } from '../data/api';
import { GetFingerprintEmailBody, GetPassportEmailBody } from '../data/MyRoutines';
import { useHistory } from "react-router-dom";
import { useQuery } from 'react-query';
import './Fingerprint311Apt.css';
interface StateProps {
  title: string
}


interface RouteProps extends RouteComponentProps<{ keyValue: string , show : string }> { }
interface GenericProps extends RouteProps, StateProps { }

const Fingerprint311Apt: React.FC<GenericProps> = ({ match, title }) => {

  const [present] = useIonAlert();

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  // Blank=B /=S :=C
  const key = (match.params.keyValue.replaceAll("B", " ").replaceAll("S", "/").replaceAll("C", ":"));

  
  const valDate = key.substring(0, 10).toString().trim();
  const valStart = key.substring(10, 22).trim();
  const valEnd = key.substring(23, 35).trim();
  
  const [typeMode, setTypeMode] = useState(false);
  const myPlaces = useQuery("GetBirthPlaces311", () => GetBirthPlaces());
  const myRaces = useQuery("GetRaces311", () => GetRaces());
  const myEyes = useQuery("GetEyeColor311", () => GetEyeColors());
  const myHairs = useQuery("GetHairColor311", () => GetHairColors());


  const [selectedDate, setselectedDate] = useState(new Date(valDate));


  const history = useHistory()
  const goBack = () => {
    history.goBack()
  }




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Fingerprint New Appointment </IonTitle>
          <IonIcon slot="end" icon={returnDownBack} size="large" class="ion-padding-end" onClick={goBack} />
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        

        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Selected Date/Time</IonCardSubtitle>
            <IonCardTitle color="danger">
              &nbsp;&nbsp;
              <IonIcon icon={star} size="large" color="danger" />
              &nbsp;&nbsp; <u>{new Date(valDate).toLocaleDateString("en-US")} {valStart} - {valEnd}</u>
            </IonCardTitle>
          </IonCardHeader>

          {match.params.show === "Y" &&
            <IonCardHeader color="warning">
              <IonItem color="transparent" href="javascript:void(0);" onClick={() => window.open('https://ucjis-tac.utah.gov/wp-content/uploads/sites/38/2021/06/User-Security-Agreement-Form-2020-updated-6282021.pdf', '_blank')} >
                <IonIcon size="large" src="../assets/icon/people-1-svgrepo-com.svg" slot="start" />
                <IonLabel class="ion-text-wrap">
                  For police department employees, please fill out this form <strong style={{ color: 'blue' }}>[UCJIS USER SECURITY AGREEMENT]</strong> with your name, date and signature.
                </IonLabel>
              </IonItem>
              <IonItem color="transparent" href="javascript:void(0);" onClick={() => window.open('https://site.utah.gov/dps-tac/wp-content/uploads/sites/38/2018/05/UCJIS-Non-User-Security-Agreement.pdf', '_blank')} >
                <IonIcon size="large" src="../assets/icon/businessman-svgrepo-com.svg" slot="start" />
                <IonLabel class="ion-text-wrap">
                  For all others, please fill out this form <strong style={{ color: 'blue' }}>[UCJIS NON-USER SECURITY AGREEMENT]</strong> with your name, date and signature.
                </IonLabel>
              </IonItem>

              <IonItem lines="none" color="transparent" >
                <IonIcon slot="start" size="large" icon={mailOutline} ></IonIcon>
                <IonLabel class="ion-text-wrap">
                  You have two convenient options for submitting the completed form:<br></br>

                  1. You can email it to utilities@orem.gov.<br></br>

                  2. Alternatively, you can print the form and bring it with you to your appointment.<br></br><br></br>

                  Once you have filled out the form above, please proceed to complete the following form and submit it.
                </IonLabel>
              </IonItem>
            </IonCardHeader>
          }
         

          <IonCardContent>
            <Formik
              initialValues={{
                lastname: "",
                firstname: "",
                middlename: "",
                email: "",
                dateofbirth: "",
                birthplace: "UT",
                race: "",
                gender: "",
                eye: "",
                hair: "",
                height: "",
                weight: ""
              }}


              validationSchema={Fingerprint311Schema}
              onSubmit={async values => {
                await new Promise(resolve => setTimeout(resolve, 200));

                // double check availability
                let avail = true;
                let startDate = valDate + ' ' + valStart;
                let endDate = valDate + ' ' + valEnd;

                const data = await IsSlotAvailable(startDate, endDate, 'F');
                if (data != true) {
                  avail = false;
                  present('Sorry that someone has just made an appointment on the time you selected. Plese select a different time period for your appointment!', [{ text: 'Ok' }])
                }



                if (avail) {
                  // Save Record
                  const data = await AddApptRecord(startDate, endDate, 'F', values.email, values.firstname.trim()+' '+values.lastname.trim() , 'A');
                  //Sending Email
                  let myContent = GetFingerprintEmailBody(values.firstname.trim() + ' ' + values.lastname.trim(), valDate, valStart, valEnd, values.email, data);

                  //Send email
                  SendEmail('noreply@orem.gov', values.email, 'City of Orem - 311 Employment Fingerprint Appointment Confirmation', myContent);
                  // Copy to utilities@orem.org
                  SendEmail('noreply@orem.gov', 'utilities@orem.gov', 'City of Orem - 311 Employment Fingerprint Appointment Confirmation (Copy) (' + values.firstname.trim()+ values.lastname.trim() + ')', myContent);


                  //Write the import file
                  WriteXMLFile(
                    values.lastname,
                    values.firstname,
                    values.middlename,
                    values.email,
                    values.dateofbirth,
                    values.birthplace,
                    values.race,
                    values.gender,
                    values.eye,
                    values.hair,
                    values.height,
                    values.weight
                  );

                  present('Your appointment has successfully made. Please check your email for conformation information.', [{ text: 'Ok' }])
                  if (match.params.show === "Y") {
                    history.push('/tabs/Fingerprint311/Y');
                  }
                  else {
                    history.push('/tabs/Fingerprint311' + {});
                  }
                  

                }

                }

              }
            >
              {(formikProps: any) => (
                <form
                  onSubmit={formikProps.handleSubmit}
                  style={{ paddingBottom: "40px" }}
                >
                  <IonItem>
                    <IonLabel>First Name:</IonLabel>
                    <IonInput
                      type="text"
                      name="firstname"
                      required={true}
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.firstname}
                    />
                    {formikProps.errors.firstname != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.firstname && formikProps.errors.firstname
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>
                  
                  <IonItem>
                    <IonLabel>Middle Name:</IonLabel>
                    <IonInput
                      type="text"
                      name="middlename"
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.middlename}
                    />
                    {formikProps.errors.middlename != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.middlename && formikProps.errors.middlename
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  <IonItem>
                    <IonLabel>Last Name:</IonLabel>
                    <IonInput
                      type="text"
                      name="lastname"
                      required={true}
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.lastname}
                    />
                    {formikProps.errors.lastname != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.lastname && formikProps.errors.lastname
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  <IonItem>
                    <IonLabel>Email:</IonLabel>
                    <IonInput
                      type="text"
                      name="email"
                      onIonChange={async (e: any) => {
                        //check next slot available
                        formikProps.values.email = e.detail.value.toLowerCase();
                        formikProps.handleChange(e);
                      }}
                      value={formikProps.values.email}
                      required={true}

                    />
                    {formikProps.errors.email != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.email && formikProps.errors.email
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>


                  <IonItem>
                    <IonLabel>Date Of Birth:</IonLabel>
                    <IonInput
                      type="text"
                      name="dateofbirth"
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.dateofbirth}
                      required={true}
                      placeholder="YYYYMMDD"
                    />
                    {formikProps.errors.dateofbirth != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.dateofbirth && formikProps.errors.dateofbirth
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  <IonItem>
                    <IonLabel>Place of Birth</IonLabel>
                    <IonSelect 
                      name="birthplace"
                      value={formikProps.values.birthplace}
                      placeholder="Select One"
                      onIonChange={formikProps.handleChange}
                     
                      >
                      {myPlaces.isSuccess && myPlaces.data.map((myplace: any) => {
                        return (
                            <IonSelectOption value={myplace.code} key={myplace.code}>{myplace.description}</IonSelectOption>                    
                             )
                            }
                            )
                       }
                       
                    </IonSelect>
                    {formikProps.errors.birthplace != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.birthplace && formikProps.errors.birthplace
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  <IonItem>
                    <IonLabel>Race</IonLabel>
                    <IonSelect
                      name="race"
                      value={formikProps.values.race}
                      placeholder="Select One"
                      onIonChange={formikProps.handleChange}
                    >

                      {myRaces.isSuccess && myRaces.data.map((myRace: any) => {
                        return (
                          <IonSelectOption value={myRace.code} key={myRace.code}>{myRace.description}</IonSelectOption>
                        )
                      }
                      )
                      }

                    </IonSelect>
                    {formikProps.errors.race != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.race && formikProps.errors.race
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>
                  

                  <IonItem>
                    <IonLabel>Gender</IonLabel>
                    <IonSelect
                      name="gender"
                      value={formikProps.values.gender}
                      placeholder="Select One"
                      onIonChange={formikProps.handleChange}
                    >
                      <IonSelectOption value="M">Male</IonSelectOption>
                      <IonSelectOption value="F">Female</IonSelectOption>
                      <IonSelectOption value="X">Unknown</IonSelectOption>
                    </IonSelect>
                    {formikProps.errors.gender != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.gender && formikProps.errors.gender
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  <IonItem>
                    <IonLabel>Eye Color</IonLabel>
                    <IonSelect
                      name="eye"
                      value={formikProps.values.eye}
                      placeholder="Select One"
                      onIonChange={formikProps.handleChange}
                    >

                      {myEyes.isSuccess && myEyes.data.map((myEye: any) => {
                        return (
                          <IonSelectOption value={myEye.code} key={myEye.code}>{myEye.description}</IonSelectOption>
                        )
                      }
                      )
                      }

                    </IonSelect>
                    {formikProps.errors.eye != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.eye && formikProps.errors.eye
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>


                  <IonItem>
                    <IonLabel>Hair Color</IonLabel>
                    <IonSelect
                      name="hair"
                      value={formikProps.values.hair}
                      placeholder="Select One"
                      onIonChange={formikProps.handleChange}
                    >

                      {myHairs.isSuccess && myHairs.data.map((myHair: any) => {
                        return (
                          <IonSelectOption value={myHair.code} key={myHair.code}>{myHair.description}</IonSelectOption>
                        )
                      }
                      )
                      }

                    </IonSelect>
                    {formikProps.errors.hair != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.hair && formikProps.errors.hair
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  <IonItem>
                    <IonLabel>Height:</IonLabel>
                    <IonInput
                      type="text"
                      name="height"
                      min="0"
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.height}
                      required={true}
                      placeholder="(e.g. 5'8 = 508)"
                    />
                    {formikProps.errors.height != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.height && formikProps.errors.height
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  <IonItem>
                    <IonLabel>Weight:</IonLabel>
                    <IonInput
                      type="text"
                      name="weight"
                      min="0"
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.weight}
                      required={true}
                     
                    />
                    {formikProps.errors.weight != null && (
                      <IonItem>
                        <IonLabel color="danger" className="ion-padding">
                          {formikProps.touched.weight && formikProps.errors.weight
                          }</IonLabel>
                      </IonItem>
                    )
                    }
                  </IonItem>

                  

                  <IonButton
                    color="primary"
                    expand="block"
                    type="submit"
                  >Submit</IonButton>
                </form>
              )}

            </Formik>

          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  )
}

export default Fingerprint311Apt;
