import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonButtons, IonInput, IonButton, IonSelect, IonSelectOption, IonIcon, useIonAlert, IonGrid } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Formik } from 'formik';
import { PassportSchema } from '../data/validations';
import { star, returnDownBack } from 'ionicons/icons';
import { IsSlotAvailable, IsNextSlotAvailable, SendEmail, AddApptRecord } from '../data/api';
import {  GetPassportEmailBody } from '../data/MyRoutines';
import { useHistory } from "react-router-dom";

interface StateProps {
  title: string
}


interface RouteProps extends RouteComponentProps<{keyValue: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const PassportApt: React.FC<GenericProps> = ({ match, title }) => {

  const [present] = useIonAlert();

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  // Blank=B /=S :=C
  const key = (match.params.keyValue.replaceAll("B", " ").replaceAll("S", "/").replaceAll("C", ":"));

  

  const valDate = key.substring(0, 10).toString().trim();
  const valStart = key.substring(10, 22).trim();
  const valEnd = key.substring(23, 35).trim();

  const [typeMode, setTypeMode] = useState(false);

  useEffect(() => {
    // The end of the day, not enough to have a family appointment
    if (valStart === "04:00:00 PM") {
      setTypeMode(true);
    }

  });
  

  const [selectedDate, setselectedDate] = useState(new Date(valDate));


  const history = useHistory()
  const goBack = () => {
    history.goBack()
  }




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Passport New Appointment </IonTitle>
          <IonIcon slot="end" icon={returnDownBack} size="large" class="ion-padding-end" onClick={goBack} />
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Selected Date/Time</IonCardSubtitle>
            <IonCardTitle color="danger">
              &nbsp;&nbsp;
              <IonIcon icon={star} size="large" color="danger" />
              &nbsp;&nbsp; <u>{new Date(valDate).toLocaleDateString("en-US")} {valStart} - {valEnd}</u>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            
            <Formik
              initialValues={{
                type: "F",
                name: "",
                email: ""
              }}

              validationSchema={PassportSchema}
              onSubmit={async values => {

                await new Promise(resolve => setTimeout(resolve, 200));
                // double check availability
                let avail = true;
                let startDate = valDate + ' ' + valStart;
                let endDate = valDate + ' ' + valEnd;

                const data = await IsSlotAvailable(startDate, endDate, 'P');
                if (data != true) {
                  avail = false;
                  present('Sorry that someone has just made an appointment on the time you selected. Plese select a different time period for your appointment!', [{ text: 'Ok' }])
                }

                //if (avail && values.type === 'F') {
                //  // check the availability of next spot
                //  const data = await IsNextSlotAvailable(startDate, endDate, 'P');
                //  if (data === false) {
                //    present('Sorry that someone has just made an appointment on the time you selected. Plese select a different time period for your appointment!', [{ text: 'Ok' }])
                //  }
                //}

                if (avail) {
                  // Save Record
                  const data = await AddApptRecord(startDate, endDate, 'P', values.email, values.name, 'A');
                  //Sending Email
                  let myContent = GetPassportEmailBody(values.name, valDate, valStart, valEnd, values.email, values.type, data);

                  SendEmail('noreply@orem.gov', values.email, 'City of Orem - Passport Appointment Confirmation', myContent);
                  // Sending copy
                  SendEmail('noreply@orem.gov', 'utilities@orem.gov', 'City of Orem - Passport Appointment Confirmation (Copy) +(' + values.name+')', myContent);

                  //if (values.type === "F") {
                  //  const data = await AddApptRecord(startDate, endDate, 'P', values.email, values.name, 'B');
                  //}

                  
                  present('Your appointment has successfully made. Please check your email for conformation information.', [{ text: 'Ok' }])
                  history.push('/tabs/Passport');
                   
                }
                //alert(JSON.stringify(values, null, 2));
              }}
            >
              {(formikProps: any) => (
                <form
                  onSubmit={formikProps.handleSubmit}
                  style={{ paddingBottom: "40px" }}
                >
                  
                  <IonItem>
                    <IonLabel>Type of Appointment</IonLabel>
                    <IonSelect
                      name="type"
                      disabled={typeMode}
                      value={formikProps.values.type}                  
                      onIonChange={async (e: any) => {
                        //check next slot available

                        let startDate = valDate + ' ' + valStart;
                        let endDate = valDate + ' ' + valEnd;

                        //if (e.detail.value === 'F') {
                        //  const data = await IsNextSlotAvailable(startDate, endDate, 'P');
                        //  if (data === false) {
                        //    present('A family appointment takes more than 30 minutes. However, the next 30 minutes of your appointment is not availabe for you. Plese select a different time period for your appointment!', [{ text: 'Ok' }])
                        //    formikProps.values.type = "I";
                        //  }
                        //  else {
                        //    formikProps.values.type = e.detail.value;
                        //  }
                        //}
                        //else {
                        //  formikProps.values.type = e.detail.value;
                        //}

                        formikProps.values.type = "F";
                        
                        formikProps.handleChange(e);
                      }}
                      >
                      {/*<IonSelectOption value="I">Individual (1-3 Persons)</IonSelectOption>*/}
                      <IonSelectOption value="F">Family (max 2 people)</IonSelectOption>
                    </IonSelect>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Name:</IonLabel>
                    <IonInput
                      type="text"
                      name="name"
                      required={true}
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.name}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Email:</IonLabel>
                    <IonInput
                      type="text"
                      name="email"
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.email}
                      required={true}
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.type && formikProps.errors.type
                      }</IonLabel>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.name && formikProps.errors.name
                      }</IonLabel>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.email && formikProps.errors.email
                      }
                    </IonLabel>
                  </IonItem>

                  <IonButton
                    color="primary"
                    expand="block"
                    type="submit"
                  >Submit</IonButton>
                </form>
              )}

            </Formik>

          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  )
}

export default PassportApt;
