import React, { useState } from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle, IonItemDivider, IonImg } from '@ionic/react';
import { calendarOutline, hammer, moonOutline, help, documentOutline, informationCircleOutline, logIn, logOut, mapOutline, peopleOutline, person, personAdd, newspaperOutline, fingerPrintOutline, rocketOutline, locationOutline, colorPaletteOutline } from 'ionicons/icons';
import './Menu.css'

const routes = {
  appPages: [

    { title: 'Passport', path: '/tabs/Passport/', icon: newspaperOutline },
    { title: 'Fingerprint 311', path: '/tabs/Fingerprint311', icon: fingerPrintOutline },
    { title: 'Fingerprint Court', path: '/tabs/FingerprintCourt', icon: fingerPrintOutline },

  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}

interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: any
}

interface MenuProps extends StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, setDarkMode }) => {

  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }
  return (
    <IonMenu  type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonItem>
            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
              <IonImg src="/assets/img/calendar.png" style={{ height: "100px", width: "100px", borderRadius: "50%", filter: darkMode ? "invert(1) hue-rotate(180deg)" : "" }} />
            </div>
          </IonItem> 
          <IonItemDivider></IonItemDivider>
          <IonListHeader>SCHEDULING SERVICES</IonListHeader>
          {renderlistItems(routes.appPages)}
          <IonItemDivider></IonItemDivider>
          <IonListHeader>SETTINGS</IonListHeader>
          <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
          <IonItemDivider></IonItemDivider>
          <IonListHeader>COLOR CODING</IonListHeader>
          <IonItem>
            <IonIcon slot="start" icon={colorPaletteOutline} color="success"></IonIcon>
            <IonLabel>Available</IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={colorPaletteOutline} color="tertiary"></IonIcon>
            <IonLabel>Taken</IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={colorPaletteOutline} color="warning"></IonIcon>
            <IonLabel>Weekend/Holiday</IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={colorPaletteOutline} color="medium"></IonIcon>
            <IonLabel>Past</IonLabel>
          </IonItem>
        </IonList>
        
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
