import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonCard, IonCardHeader,  IonCardContent, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonText, IonDatetime, IonIcon,  IonItemGroup, IonItemDivider, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useQuery } from 'react-query';
import { GetAppointmentsByDate } from '../data/api';
import { Link, RouteComponentProps} from 'react-router-dom';
import './Passport.css';
import {  arrowDownCircle, chevronForwardCircle, homeOutline } from 'ionicons/icons';

interface StateProps {
  title: string
}


interface RouteProps extends RouteComponentProps<{ dateValue: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const Passport: React.FC<GenericProps> = ({ match, title }) => {


 //---Set Intial Value of the Date--------------------------------

  if (match.params.dateValue == null) {
    match.params.dateValue = new Date().toISOString();
  }

  let today = new Date(new Date(match.params.dateValue).toDateString());

  const [windowHeight, setWindowHeight] = useState<string>('450px');
  const [selectedDate, setSelectedDate] = useState<string>(today.toISOString());

  // USE EFFECT
  useEffect(() => {
    if (selectedDate !== null) {
      var newDate = new Date(Date.parse(selectedDate));
      if (newDate.getDay() === 4) {
        setWindowHeight('800px');
      }
    }
    
  }, [selectedDate]);


  //---GetDatetList--------------------------------
  function GetDatetList(props: any) {
    const myQuery = useQuery("GetAppointmentsByDate", () => GetAppointmentsByDate(props.myType, props.myDate));
    return (
      <div>
        {myQuery.isLoading && (
          <IonItem>
            Loading
          </IonItem>
        )}
        {
          myQuery.isSuccess && myQuery.data.map((dateString: any) =>
          (

            /*availabe: #2dd36f  taken:#5260ff  holiday:#ffc409  past: #92949c             */

            <IonItem key={dateString.aptkey}>
              <div style={{
                backgroundColor: dateString.aptsts === 'N' ? '#5260ff' :
                  dateString.aptsts === 'H' ? '#ffc409' :
                    dateString.aptsts === 'P' ? '#92949c'
                      : '#2dd36f', width: "4px"
              }} slot="start" >&nbsp;</div>
              <div>
                {dateString.aptsts === 'A' ? (
                  <div>
                    <Link 
                      to={{
                        pathname: "/tabs/passportapt/" + encodeURIComponent(dateString.aptkey.toString())
                      }}>
                      <IonLabel>
                      {dateString.aptst}
                        -
                      {dateString.apted}
                        </IonLabel>
                    </Link>
                  </div>
                ) :
                  (
                   
                    <div style={{ color: "lightgray" }} >
                      <IonLabel>
                      {dateString.aptst}
                        - 
                      {dateString.apted}
                      </IonLabel>
                    </div>
                      

                  )
                }
              </div>
             
              <div slot="end" style={{ color: "lightgray" }}>
                <IonLabel>
                  {dateString.aptname}
                  </IonLabel>
              </div>

            </IonItem>))
        }
      </div>
      );
  }

return (
  <IonPage>
    
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle color="primary">Passport Services</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent color="light">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonCard color="warning">
              <IonCardHeader>
                <IonItemGroup className="instructionbox">
                  <IonText>
                    <IonIcon icon={homeOutline} size="large"></IonIcon><br></br>
                    311 Help Desk (Room 110)<br></br>
                    56 North State Street, Orem, UT 84057<br></br>
                    Monday thru Thursday: 07:30 AM to 03:30 PM<br></br>
                    Friday: 8:00 AM to 3:30 PM<br></br>
                    Saturday & Sunday:  Closed
                  </IonText>
                </IonItemGroup>
                <IonText class="instruction">
                  <br></br>
                  *To schedule an appointment, click a green open-slot below.<br></br>
                </IonText>

              </IonCardHeader>
            </IonCard>
          </IonCol>

            <IonCol size="12" sizeSm="6">
            <IonCard style={{ height: windowHeight }}>
                <IonCardContent>
                  <IonItem lines="none">
                    <IonIcon slot="start" icon={arrowDownCircle} color="primary"></IonIcon>
                    <IonLabel>
                    Selected Date  
                    </IonLabel>
                  </IonItem>
                <IonItem mode="ios" lines="none" >
                  <IonDatetime
                    value={selectedDate}
                    presentation="date"
                    onIonChange={(e: any) => { setSelectedDate(e.detail.value!) }}
                    placeholder="Select Time"
                  >
                  </IonDatetime>

                  </IonItem>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeSm="6" >
            <IonCard style={{ height: windowHeight + 100 }}>
                <IonCardContent>
                  <IonList>
                    <GetDatetList myType='P' myDate={selectedDate} />
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
         
          </IonRow>
      </IonGrid>

    

      

    </IonContent>
  </IonPage>
);
}

export default Passport;
