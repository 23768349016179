import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonButtons, IonInput, IonButton, IonIcon, useIonAlert } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Formik } from 'formik';
import { CourtSchema } from '../data/validations';
import { star, returnDownBack } from 'ionicons/icons';
import { IsSlotAvailable, SendEmail, AddApptRecord } from '../data/api';
import { GetCourtFingerprintEmailBody } from '../data/MyRoutines';
import { useHistory } from "react-router-dom";

interface StateProps {
  title: string
}


interface RouteProps extends RouteComponentProps<{ keyValue: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const FingerprintCourtApt: React.FC<GenericProps> = ({ match, title }) => {

  const [present] = useIonAlert();

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  // Blank=B /=S :=C
  const key = (match.params.keyValue.replaceAll("B", " ").replaceAll("S", "/").replaceAll("C", ":"));


  const valDate = key.substring(0, 10).toString().trim();
  const valStart = key.substring(10, 22).trim();
  const valEnd = key.substring(23, 35).trim();

  const [typeMode, setTypeMode] = useState(false);


  const [selectedDate, setselectedDate] = useState(new Date(valDate));


  const history = useHistory()
  const goBack = () => {
    history.goBack()
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Court New Appointment </IonTitle>
          <IonIcon slot="end" icon={returnDownBack} size="large" class="ion-padding-end" onClick={goBack} />
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Selected Date/Time</IonCardSubtitle>
            <IonCardTitle color="danger">
              &nbsp;&nbsp;
              <IonIcon icon={star} size="large" color="danger" />
              &nbsp;&nbsp; <u>{new Date(valDate).toLocaleDateString("en-US")} {valStart} - {valEnd}</u>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <Formik
              initialValues={{
                type: "I",
                name: "",
                email: "",
                birthdate: null
              }}

              validationSchema={CourtSchema}
              onSubmit={async values => {

                await new Promise(resolve => setTimeout(resolve, 200));
                // double check availability
                let avail = true;
                let startDate = valDate + ' ' + valStart;
                let endDate = valDate + ' ' + valEnd;

                const data = await IsSlotAvailable(startDate, endDate, 'C');
                if (data != true) {
                  avail = false;
                  present('Sorry that someone has just made an appointment on the time you selected. Plese select a different time period for your appointment!', [{ text: 'Ok' }])
                }

             

                if (avail) {
                  // Save Record
                  const data = await AddApptRecord(startDate, endDate, 'C', values.email, values.name, 'A');
                  //Sending Email
                  let myContent = GetCourtFingerprintEmailBody(values.name, valDate, valStart, valEnd, values.email, values.birthdate, data);

                  // Send email
                  SendEmail('noreply@orem.gov', values.email.trim(), 'City of Orem - Justice Court Fingerprint Confirmation', myContent);
                  // Copy to court account
                  SendEmail('noreply@orem.gov', 'court@orem.gov', 'City of Orem - Justice Court Fingerprint Appointment Confirmation (Copy) (' + values.name.trim() + ')', myContent);

                  present('Your appointment has successfully made. Please check your email for conformation information.', [{ text: 'Ok' }])
                  history.push('/tabs/FingerprintCourt');

                }
                //alert(JSON.stringify(values, null, 2));
              }}
            >
              {(formikProps: any) => (
                <form
                  onSubmit={formikProps.handleSubmit}
                  style={{ paddingBottom: "40px" }}
                >

            
                  <IonItem>
                    <IonLabel>Name:</IonLabel>
                    <IonInput
                      type="text"
                      name="name"
                      required={true}
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.name}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Email:</IonLabel>
                    <IonInput
                      type="text"
                      name="email"
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.email}
                      required={true}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Date of Birth:</IonLabel>
                    <IonInput
                      type="date"
                      name="birthdate"
                      onIonChange={formikProps.handleChange}
                      value={formikProps.values.birthdate}
                      required={true}
                    />
                  </IonItem>


                  <IonItem>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.name && formikProps.errors.name
                      }</IonLabel>
                    <IonLabel color="danger" className="ion-padding">
                      {formikProps.touched.email && formikProps.errors.email
                      }
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.birthdate && formikProps.errors.type
                        }</IonLabel>
                    </IonLabel>
                  </IonItem>

                  <IonButton
                    color="primary"
                    expand="block"
                    type="submit"
                  >Submit</IonButton>
                </form>
              )}

            </Formik>

          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  )
}

export default FingerprintCourtApt;
