import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { setupIonicReact } from '@ionic/react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MainTabs from './pages/MainTabs';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';


setupIonicReact({ mode: 'md', });


const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <IonicApp />
    </QueryClientProvider>
  );
};


const IonicApp: React.FC = () => {
  const [darkMode, setDarkMode] = useState(false);
  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
     {/*<ReactQueryDevtools />*/}
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu darkMode={darkMode} setDarkMode={setDarkMode} />
          <IonRouterOutlet id="main">
            {/*
                We use IonRoute here to keep the tabs state intact,
                which makes transitions between tabs and non tab pages smooth
                */}
            <Redirect exact path="/" to="/tabs" />
            <Route path="/tabs" render={() => <MainTabs />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
