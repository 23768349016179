import * as yup from 'yup';

export const PassportSchema = yup.object({
  type: yup
    .string()
    .required('*Required'),
  name: yup
    .string()
    .min(2, '*Too Short!')
    .max(50, '*Too Long!')
    .required('*Required'),
  email: yup
    .string()
    .email('*Invalid email')
    .required('*Required')
});



export const CourtSchema = yup.object({
  type: yup
    .string()
    .required('*Required'),
  name: yup
    .string()
    .min(2, '*Too Short!')
    .max(50, '*Too Long!')
    .required('*Required'),
  email: yup
    .string()
    .email('*Invalid email')
    .required('*Required'),
  birthdate: yup
    .date('*Invalid date')
    .required('*Required')

});     


export const Fingerprint311Schema = yup.object({
  firstname: yup
    .string()
    .min(2, '*Too Short!')
    .max(50, '*Too Long!')
    .required('*Required'),
  lastname: yup
    .string()
    .min(2, '*Too Short!')
    .max(50, '*Too Long!')
    .required('*Required'),

  middlename: yup
    .string()
    .nullable(),

  email: yup
    .string()
    .email('*Invalid')
    .required('*Required'),

  dateofbirth: yup
    .number()
    .min(19100000, '*Invalid Date')
    .max(99999999, '*Invalid Date')
    .typeError('*Numbers only')
    .required('*Required'),

  birthplace: yup
    .string()
    .required('*Required'),

  height: yup
    .number()
    .min(300, '*Min value 300')
    .max(800, '*Max value 800')
    .required('*Required'),

  weight: yup
    .number()
    .min(50, '*Min value 50')
    .max(400, '*Max value 400')
    .required('*Required'),

  race: yup
    .string()
    .required('*Required'),

  birthplace: yup
    .string()
    .required('*Required'),

  gender: yup
    .string()
    .required('*Required'),

  eye: yup
    .string()
    .required('*Required'),

  hair: yup
    .string()
    .required('*Required')

    });
