import React, { useState } from 'react';
import { IonContent, IonPage, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonText, IonDatetime, IonIcon, IonGrid, IonRow, IonCol, IonItemGroup, IonItemDivider, useIonAlert, useIonModal } from '@ionic/react';
import { useMutation, useQuery } from 'react-query';
import { AppointmentExists, CancelAppointment, GetEmployeeInfo, SendEmail} from '../data/api';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import './Passport.css';
import { alertCircleOutline, text } from 'ionicons/icons';
import { GetCancelEmailBody } from '../data/MyRoutines';


interface StateProps {
  title: string
}


interface RouteProps extends RouteComponentProps<{ keyvalue: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const Cancel: React.FC<GenericProps> = ({ match, title }) => {

  const key = decodeURI(match.params.keyvalue);
  const [present, dismiss1] = useIonAlert();
  const [present2, dismiss2] = useIonAlert();
  const myQuery = useQuery("AppointmentExists", () => AppointmentExists(key));
  const myPerson = useQuery("GetEmployeeInfo", async () => GetEmployeeInfo(key));


  function RemoveAppointment(props: any) {
    let key = props;
    CancelAppointment(key);
    if (myPerson.data.aptname != null) {
          let myContent = GetCancelEmailBody(myPerson.data.aptname,
             myPerson.data.aptdate, myPerson.data.aptstart, myPerson.data.aptend, myPerson.data.aptemail, myPerson.data.apttype); 

      if (myPerson.data.aptemail != null) {
        //Send email
         SendEmail('noreply@orem.gov', myPerson.data.aptemail, 'City of Orem - Appointment Cancellation Confirmation', myContent);
      }

    }
  }
  
  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Cancel Appointment Confirmation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
       
        {myQuery.data && myQuery.data.length > 0 && myQuery.data == '0' && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
              <IonIcon icon={alertCircleOutline} size="large" slot="start"></IonIcon>
              <IonText>
                Sorry, we are unable to locate your appointment: it may be already cancelled or it has already passed its scheduled date and time.
              </IonText>
            </IonItem>
            </IonCardContent>
          </IonCard>)
        }

        {myQuery.data && myQuery.data.length > 0 && myQuery.data == '1' &&
          (
            present('Are you sure that you want to cancel the appointment?', [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                present2('You have changed your mind. You have decided to keep your appointment with City of Orem.', [{ text: 'Ok' }])        
              }
            },
            {
              text: 'Okay',
              handler: () => {
                // Do my stuff
                RemoveAppointment(key);
                present2('Your appointment has been successfully cancelled!', [{ text: 'Ok' }]);
                /*event.preventDefault();*/
              }
            }
          ]))
        }

      </IonContent>
    </IonPage>
  );
}

export default Cancel;
